
;(function(window, document, $) {
	var $win = $(window);
	var $doc = $(document);
	const $sliderBlocks = $('.slider-blocks .slider__slides');
	const $sliderBoxes = $('.slider-boxes .slider__slides');
	const $sliderLarge = $('.slider-large .slider__slides');
	const $sliderThumbs = $('.slider-thumbs .slider__slides');
	const $sliderImage = $('.slider-image .slider__slides');

	$sliderBlocks.slick({
	  dots: true,
	  infinite: true,
	  slidesToShow: 1,
	  slidesToScroll: 1,
	  centerMode: false,
	  variableWidth: false,
      adaptiveHeight: true,
      useTransform: false,
	  arrows: true,
	  customPaging: function (slick, index) {
          return '<a>' + (index + 1) + '</a>';
      }
	});

	$sliderBoxes.slick({
	  dots: false,
	  infinite: false,
	  slidesToShow: 3,
	  slidesToScroll: 1,
	  centerMode: false,
	  variableWidth: false,
	  useTransform: false,
	  arrows: true,

	  responsive: [
	    {
	      breakpoint: 1024,
	      settings: {
	        slidesToShow: 2,
	        slidesToScroll: 1,
	      }
	    },
	    {
	      breakpoint: 768,
	      settings: {
	        slidesToShow: 1,
	        slidesToScroll: 1
	      }
	    },
	  ]
	});

	$sliderImage.slick({
	  dots: false,
	  infinite: false,
	  slidesToShow: 1,
	  slidesToScroll: 1,
	  centerMode: false,
	  variableWidth: false,
	  useTransform: false,
	  arrows: true,
	});

    $('.slider-large .slider__slides').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        fade: false,
        asNavFor: $('.slider-thumbs .slider__slides')
    }).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        var width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
        if (width <= 767) {
            $('html, body').animate({
                scrollTop: $('.slider-large').offset().top - 150
            }, 800);
        }
    });

    $('.slider-thumbs .slider__slides').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: $('.slider-large .slider__slides'),
        dots: false,
        arrows: false,
        centerMode: false,
        focusOnSelect: true,
        useTransform: false
    });

	$('.nav-trigger').on('click', function(event) {
		event.preventDefault();
		$(this).toggleClass('nav-trigger--active');

		$('.nav').slideToggle();
	});
})(window, document, window.jQuery);

function initMap() {
    var mapOptions = {
        zoom: 18,
        center: {lat: 48.853709, lng: 2.361531},
        zoomControl: true,
        scaleControl: true,
        styles: [
		    {
		        "featureType": "water",
		        "elementType": "geometry.fill",
		        "stylers": [
		            {
		                "color": "#d3d3d3"
		            }
		        ]
		    },
		    {
		        "featureType": "transit",
		        "stylers": [
		            {
		                "color": "#808080"
		            },
		            {
		                "visibility": "off"
		            }
		        ]
		    },
		    {
		        "featureType": "road.highway",
		        "elementType": "geometry.stroke",
		        "stylers": [
		            {
		                "visibility": "on"
		            },
		            {
		                "color": "#b3b3b3"
		            }
		        ]
		    },
		    {
		        "featureType": "road.highway",
		        "elementType": "geometry.fill",
		        "stylers": [
		            {
		                "color": "#ffffff"
		            }
		        ]
		    },
		    {
		        "featureType": "road.local",
		        "elementType": "geometry.fill",
		        "stylers": [
		            {
		                "visibility": "on"
		            },
		            {
		                "color": "#ffffff"
		            },
		            {
		                "weight": 1.8
		            }
		        ]
		    },
		    {
		        "featureType": "road.local",
		        "elementType": "geometry.stroke",
		        "stylers": [
		            {
		                "color": "#d7d7d7"
		            }
		        ]
		    },
		    {
		        "featureType": "poi",
		        "elementType": "geometry.fill",
		        "stylers": [
		            {
		                "visibility": "on"
		            },
		            {
		                "color": "#ebebeb"
		            }
		        ]
		    },
		    {
		        "featureType": "administrative",
		        "elementType": "geometry",
		        "stylers": [
		            {
		                "color": "#a7a7a7"
		            }
		        ]
		    },
		    {
		        "featureType": "road.arterial",
		        "elementType": "geometry.fill",
		        "stylers": [
		            {
		                "color": "#ffffff"
		            }
		        ]
		    },
		    {
		        "featureType": "road.arterial",
		        "elementType": "geometry.fill",
		        "stylers": [
		            {
		                "color": "#ffffff"
		            }
		        ]
		    },
		    {
		        "featureType": "landscape",
		        "elementType": "geometry.fill",
		        "stylers": [
		            {
		                "visibility": "on"
		            },
		            {
		                "color": "#efefef"
		            }
		        ]
		    },
		    {
		        "featureType": "road",
		        "elementType": "labels.text.fill",
		        "stylers": [
		            {
		                "color": "#696969"
		            }
		        ]
		    },
		    {
		        "featureType": "administrative",
		        "elementType": "labels.text.fill",
		        "stylers": [
		            {
		                "visibility": "on"
		            },
		            {
		                "color": "#737373"
		            }
		        ]
		    },
		    {
		        "featureType": "poi",
		        "elementType": "labels.icon",
		        "stylers": [
		            {
		                "visibility": "off"
		            }
		        ]
		    },
		    {
		        "featureType": "poi",
		        "elementType": "labels",
		        "stylers": [
		            {
		                "visibility": "off"
		            }
		        ]
		    },
		    {
		        "featureType": "road.arterial",
		        "elementType": "geometry.stroke",
		        "stylers": [
		            {
		                "color": "#d6d6d6"
		            }
		        ]
		    },
		    {
		        "featureType": "road",
		        "elementType": "labels.icon",
		        "stylers": [
		            {
		                "visibility": "off"
		            }
		        ]
		    },
		    {},
		    {
		        "featureType": "poi",
		        "elementType": "geometry.fill",
		        "stylers": [
		            {
		                "color": "#dadada"
		            }
		        ]
		    }
		]
    }

    var map = new google.maps.Map(document.getElementById('map'), mapOptions);

    var marker = new google.maps.Marker({
        position: new google.maps.LatLng(48.853709, 2.361531),
        map: map,
        icon: 'assets/images/pin.png'
    });

	if (window.innerWidth < 1024) {
	    map.panBy(232, 0);
	} else {
	    map.panBy(0, 0);
	}

	if (window.innerWidth < 768) {
	    map.panBy(-150, 0);
	} else {
	    map.panBy(0, 0);
	}
}

if (document.getElementById('map')) {
    //initMap();
}

// SHOP AJAX FORM
$('#r-all').on('change', function(e) {
    if (this.checked) {
        $(this).parents('.filters').find('input[type=checkbox]').attr('checked', true);
    }
});

function submitShopForm() {
    var data = $('.shop-ajax-form').serialize();
    $('.ajax-shop-results').css('opacity', '0.2');

    $.ajax({
        'url': ajaxurl,
        'type': 'GET',
        'async': true,
        'data': data + '&action=get_shop_ajax_action',
        success: function (response) {
            var res = JSON.parse(response);
            $('.ajax-shop-results').empty().append(res);
            $('.ajax-shop-results').css('opacity', '1');
        }
    });
}

function doneTypingShop() {
    var xhr;

    if (xhr) {
        xhr.abort();
    }

    submitShopForm();
}

$('.shop-ajax-form input[type=checkbox]').on('change', function(e) {
    submitShopForm();
});

var typingTimer; //timer identifier
var doneTypingInterval = 500; //time in ms

$('.shop-ajax-input').on('change keyup paste click', function (ev) {
    clearTimeout(typingTimer);
    if ($(this).val().length >= 0) {
        typingTimer = setTimeout(doneTypingShop, doneTypingInterval);
    }
});

$('.ajax-shop-results').on('click', '.paginate a', function(e) {
    e.preventDefault();
    var link = $(this).attr('href');

    if (link.includes("page")) {
        var newLink = link.substring(link.indexOf("page") - 1);
    } else {
        var newLink = link.substring(link.indexOf("?search_shop") - 1);
    }

    var params = newLink
        .replace(/page=[0-9]&/gi, '')
        .replace('/page/', 'page=')
        .replace('/?', '&');

    if (params.slice(-1) == '/') {
        params = $('.shop-ajax-form').serialize() + '&' + params.substring(0, params.length - 1) + '&action=get_shop_ajax_action';
    }

    $('.ajax-shop-results').css('opacity', '0.2');

    $.ajax({
        'url': ajaxurl,
        'type': 'GET',
        'async': true,
        'data': params,
        success: function (response) {
            var res = JSON.parse(response);
            $('.ajax-shop-results').empty().append(res);
            $('.ajax-shop-results').css('opacity', '1');
            $('html, body').animate({
                scrollTop: $('.ajax-shop-results').offset().top
            }, 800);
        }
    });
})


// RESTAURANT AJAX FORM
function submitRestaurantForm() {
    var data = $('.restaurant-ajax-form').serialize();
    $('.ajax-restaurant-results').css('opacity', '0.2');

    $.ajax({
        'url': ajaxurl,
        'type': 'GET',
        'async': true,
        'data': data + '&action=get_restaurant_ajax_action',
        success: function (response) {
            var res = JSON.parse(response);
            $('.ajax-restaurant-results').empty().append(res);
            $('.ajax-restaurant-results').css('opacity', '1');
        }
    });
}

function doneTypingRestaurant() {
    var xhr;

    if (xhr) {
        xhr.abort();
    }

    submitRestaurantForm();
}

$('.restaurant-ajax-form input[type=checkbox]').on('change', function(e) {
    submitRestaurantForm();
});

$('.restaurant-ajax-input').on('change keyup paste click', function (ev) {
    clearTimeout(typingTimer);
    if ($(this).val().length >= 0) {
        typingTimer = setTimeout(doneTypingRestaurant, doneTypingInterval);
    }
});

$('.ajax-restaurant-results').on('click', '.paginate a', function(e) {
    e.preventDefault();
    var link = $(this).attr('href');

    if (link.includes("page")) {
        var newLink = link.substring(link.indexOf("page") - 1);
    } else {
        var newLink = link.substring(link.indexOf("?search_restaurant") - 1);
    }

    var params = newLink
        .replace(/page=[0-9]&/gi, '')
        .replace('/page/', 'page=')
        .replace('/?', '&');

    if (params.slice(-1) == '/') {
        params = $('.restaurant-ajax-form').serialize() + '&' + params.substring(0, params.length - 1) + '&action=get_restaurant_ajax_action';
    }

    $('.ajax-restaurant-results').css('opacity', '0.2');

    $.ajax({
        'url': ajaxurl,
        'type': 'GET',
        'async': true,
        'data': params,
        success: function (response) {
            var res = JSON.parse(response);
            $('.ajax-restaurant-results').empty().append(res);
            $('.ajax-restaurant-results').css('opacity', '1');
            $('html, body').animate({
                scrollTop: $('.ajax-restaurant-results').offset().top
            }, 800);
        }
    });
})

// POST AJAX FORM
function submitPostForm() {
    var data = $('.post-ajax-form').serialize();
    $('.ajax-post-results').css('opacity', '0.2');

    $.ajax({
        'url': ajaxurl,
        'type': 'GET',
        'async': true,
        'data': data + '&action=get_post_ajax_action',
        success: function (response) {
            var res = JSON.parse(response);
            $('.ajax-post-results').empty().append(res);
            $('.ajax-post-results').css('opacity', '1');
        }
    });
}

function doneTypingPost() {
    var xhr;

    if (xhr) {
        xhr.abort();
    }

    submitPostForm();
}

$('.post-ajax-form input[type=checkbox]').on('change', function(e) {
    submitPostForm();
});

$('.post-ajax-input').on('change keyup paste click', function (ev) {
    clearTimeout(typingTimer);
    if ($(this).val().length >= 0) {
        typingTimer = setTimeout(doneTypingPost, doneTypingInterval);
    }
});

$('.ajax-post-results').on('click', '.paginate a', function(e) {
    e.preventDefault();
    var link = $(this).attr('href');

    if (link.includes("page")) {
        var newLink = link.substring(link.indexOf("page") - 1);
    } else {
        var newLink = link.substring(link.indexOf("?search_post") - 1);
    }

    var params = newLink
        .replace(/page=[0-9]&/gi, '')
        .replace('/page/', 'page=')
        .replace('/?', '&');

    if (params.slice(-1) == '/') {
        params = $('.post-ajax-form').serialize() + '&' + params.substring(0, params.length - 1) + '&action=get_post_ajax_action';
    }

    $('.ajax-post-results').css('opacity', '0.2');

    $.ajax({
        'url': ajaxurl,
        'type': 'GET',
        'async': true,
        'data': params,
        success: function (response) {
            var res = JSON.parse(response);
            $('.ajax-post-results').empty().append(res);
            $('.ajax-post-results').css('opacity', '1');
            $('html, body').animate({
                scrollTop: $('.ajax-post-results').offset().top
            }, 800);
        }
    });
})

function submitSearchForm() {
    var data = $('.search-ajax-form').serialize();
    $('.ajax-search-results').css('opacity', '0.2');

    $.ajax({
        'url': ajaxurl,
        'type': 'GET',
        'async': true,
        'data': data + '&action=get_search_ajax_action',
        success: function (response) {
            var res = JSON.parse(response);
            $('.ajax-search-results').empty().append(res);
            $('.ajax-search-results').css('opacity', '1');
        }
    });
}

function doneTypingSearch() {
    var xhr;

    if (xhr) {
        xhr.abort();
    }

    submitSearchForm();
}

$('.search-ajax-input').on('change keyup paste click', function (ev) {
    clearTimeout(typingTimer);
    if ($(this).val().length >= 0) {
        typingTimer = setTimeout(doneTypingSearch, doneTypingInterval);
    }
});

$('.ajax-search-results').on('click', '.paginate a', function(e) {
    e.preventDefault();
    var link = $(this).attr('href');

    if (link.includes("page")) {
        var newLink = link.substring(link.indexOf("page") - 1);
    } else {
        var newLink = link.substring(link.indexOf("?search_page") - 1);
    }

    var params = newLink
        .replace(/page=[0-9]&/gi, '')
        .replace('/page/', 'page=')
        .replace('/?', '&');

    if (params.slice(-1) == '/') {
        params = $('.search-ajax-form').serialize() + '&' + params.substring(0, params.length - 1) + '&action=get_search_ajax_action';
    }

    $('.ajax-search-results').css('opacity', '0.2');

    $.ajax({
        'url': ajaxurl,
        'type': 'GET',
        'async': true,
        'data': params,
        success: function (response) {
            var res = JSON.parse(response);
            $('.ajax-search-results').empty().append(res);
            $('.ajax-search-results').css('opacity', '1');
            $('html, body').animate({
                scrollTop: $('.ajax-search-results').offset().top - 200
            }, 800);
        }
    });
})